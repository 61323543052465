import Select from '@components/elements/Form/Select';
import PAYMENT_TYPES from '@constants/paymentTypes';

import BlikInfo from './BlikInfo/BlikInfo';
import PayPoInfo from './PayPoInfo/PayPoInfo';
import PayUInfo from './PayUInfo/PayUInfo';

type PaymentTypesReorderPageProps = {
  sortedPaymentTypes: string[];
  selectedPaymentType: string;
  handleClickPayment: (paymentType: string) => void;
  formatOptionLabel: (option: { value: string }) => JSX.Element;
};

const PaymentTypesReorderPage = ({
  sortedPaymentTypes,
  selectedPaymentType,
  handleClickPayment,
  formatOptionLabel,
}: PaymentTypesReorderPageProps) => {
  return (
    <div tw="mb-4">
      {sortedPaymentTypes.length > 1 && (
        <Select
          name="paymentType"
          tw="w-full"
          options={sortedPaymentTypes.map(paymentType => ({
            value: paymentType,
          }))}
          value={{ value: selectedPaymentType, isSelected: true }}
          onChange={({ value }: { value: string }) => {
            handleClickPayment(value);
          }}
          formatOptionLabel={formatOptionLabel}
        />
      )}
      {selectedPaymentType === PAYMENT_TYPES.PAYU_BLIK && (
        <BlikInfo reorderPage />
      )}

      {(selectedPaymentType === PAYMENT_TYPES.PAYPO ||
        selectedPaymentType === PAYMENT_TYPES.PAYU_PAYPO) && (
        <PayPoInfo reorderPage />
      )}
      {selectedPaymentType === PAYMENT_TYPES.PAYU && <PayUInfo reorderPage />}
    </div>
  );
};

export default PaymentTypesReorderPage;
