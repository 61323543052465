import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import PAYMENT_TYPES from '@constants/paymentTypes';
import usePayUPaymentMethods from '@hooks/queries/usePayUPaymentMethods';
import useBasketMethods from '@hooks/useBasketMethods';
import usePaymentCards from '@hooks/usePaymentCards';
import checkIfPaymentTypesIncludesCard from '@utils/checkIfPaymentTypesIncludesCard';

import PaymentType from './PaymentType';
import PaymentTypesReorderPage from './PaymentTypesReorderPage';
import usePaymentTypeProps from './usePaymentTypeProps';

const PayPoModal = dynamic(() => import('./PayPoModal/PayPoModal'));
const CardModal = dynamic(() => import('./CardModal'));

const additionalMetadataPaymentTypes = [
  PAYMENT_TYPES.PAYU_CARD,
  PAYMENT_TYPES.STRIPE_CARD,
  PAYMENT_TYPES.PAYPO,
  PAYMENT_TYPES.PAYU_PAYPO,
];

const PaymentTypes = ({
  onClickConfirmBlueMediaBlik = () => {},
  onClickConfirmPayuBlik = () => {},
  onClickPayment = () => {},
  payments = [],
  selectedPaymentType = null,
  blikCode,
  setBlikCode,
  isBlikExternalMode = false,
  isSelectMode = false,
}) => {
  const { t } = useTranslation();

  const { data: cards = [] } = usePaymentCards({
    enabled: checkIfPaymentTypesIncludesCard(payments),
  });
  const {
    basketQuery: { data: basket = {} },
  } = useBasketMethods();

  const [modalDemandingPaymentType, setModalDemandingPaymentType] =
    useState('');

  const [isStripeCardModalOpen, setIsStripeCardModalOpen] = useState(null);
  const openStripeCardModal = () => {
    setIsStripeCardModalOpen(true);
  };
  const closeStripeCardModal = () => {
    setIsStripeCardModalOpen(false);
    setModalDemandingPaymentType('');
  };

  const [isPayUCardModalOpen, setIsPayUCardModalOpen] = useState(null);
  const openPayUCardModal = () => {
    setIsPayUCardModalOpen(true);
  };
  const closePayUCardModal = () => {
    setIsPayUCardModalOpen(false);
    setModalDemandingPaymentType('');
  };

  const [isPayPoModalOpen, setIsPayPoModalOpen] = useState(null);
  const openPayPoModal = () => {
    setIsPayPoModalOpen(true);
  };
  const closePayPoModal = () => {
    setIsPayPoModalOpen(false);
    setModalDemandingPaymentType('');
  };

  const isSelectedProviderCardAvailable = !isEmpty(
    cards?.find(({ provider }) => provider === selectedPaymentType)
  );

  const { data: payMethods = [] } = usePayUPaymentMethods({
    enabled: (!isEmpty(payments) ? payments : []).includes(
      PAYMENT_TYPES.PAYU_PAY_BY_LINKS
    ),
  });

  useEffect(() => {
    if (
      [PAYMENT_TYPES.STRIPE_CARD, PAYMENT_TYPES.PAYU_CARD].includes(
        selectedPaymentType
      ) &&
      !isSelectedProviderCardAvailable
    ) {
      switch (selectedPaymentType) {
        case PAYMENT_TYPES.STRIPE_CARD:
          openStripeCardModal();
          break;
        case PAYMENT_TYPES.PAYU_CARD:
          openPayUCardModal();
          break;
        default:
          break;
      }
    }
  }, []);

  const currentPayMethodName = (!isEmpty(payMethods) ? payMethods : [])?.find(
    method => method.value === basket.paymentMetadata?.payuPblValue
  )?.name;

  const paymentTypeProps = usePaymentTypeProps({
    blikCode,
    payMethods,
    setBlikCode,
    selectedPaymentType,
    onClickPayment,
    currentPayMethodName,
    isBlikExternalMode,
  });

  const sortPaymentTypes = paymentTypes => {
    const paymentsOrder = [
      PAYMENT_TYPES.PAYU_BLIK,
      PAYMENT_TYPES.PAYU_CARD,
      PAYMENT_TYPES.PAYU,
      PAYMENT_TYPES.BLUE_MEDIA_BLIK,
      PAYMENT_TYPES.BLUE_MEDIA_CARD,
      PAYMENT_TYPES.BLUE_MEDIA,
      PAYMENT_TYPES.PAYNOW,
      PAYMENT_TYPES.TPAY,
      PAYMENT_TYPES.PAYPO,
      PAYMENT_TYPES.PAYU_PAYPO,
      PAYMENT_TYPES.STRIPE_LINK,
      PAYMENT_TYPES.STRIPE_CARD,
      PAYMENT_TYPES.BANK_WIRE,
      PAYMENT_TYPES.CASH,
      PAYMENT_TYPES.PAYU_PAY_BY_LINKS,
    ];

    const sortedPaymentsTypes = paymentsOrder.reduce((acc, type) => {
      const paymentType = paymentTypes.find(
        paymentType => paymentType === type
      );

      if (!isEmpty(paymentType)) {
        return [...acc, paymentType];
      }

      return [...acc];
    }, []);

    return sortedPaymentsTypes;
  };

  const sortedPaymentTypes = sortPaymentTypes(payments);

  const isInformationObligationsRequired = Object.values(
    sortedPaymentTypes
  ).some(type => type.includes(PAYMENT_TYPES.PAYU_BLIK));

  const handleClickPayment = paymentType => {
    if (paymentType === PAYMENT_TYPES.BLUE_MEDIA_BLIK && isBlikExternalMode) {
      return onClickConfirmBlueMediaBlik();
    }

    if (paymentType === PAYMENT_TYPES.PAYU_BLIK && isBlikExternalMode) {
      return onClickConfirmPayuBlik();
    }

    if (
      (paymentType === PAYMENT_TYPES.STRIPE_CARD &&
        isEmpty(
          cards.find(card => card?.provider === PAYMENT_TYPES.STRIPE_CARD)
        )) ||
      (paymentType === PAYMENT_TYPES.PAYU_CARD &&
        isEmpty(
          cards.find(card => card?.provider === PAYMENT_TYPES.PAYU_CARD)
        )) ||
      [PAYMENT_TYPES.PAYPO, PAYMENT_TYPES.PAYU_PAYPO].includes(paymentType)
    ) {
      setModalDemandingPaymentType(paymentType);

      return;
    }

    return onClickPayment({ paymentType });
  };

  useEffect(() => {
    if (
      [PAYMENT_TYPES.PAYPO, PAYMENT_TYPES.PAYU_PAYPO].includes(
        modalDemandingPaymentType
      )
    ) {
      openPayPoModal();
      return;
    }

    if (
      modalDemandingPaymentType === PAYMENT_TYPES.PAYU_CARD &&
      isEmpty(cards.find(card => card?.provider === PAYMENT_TYPES.PAYU_CARD))
    ) {
      openPayUCardModal();
      return;
    }

    if (
      modalDemandingPaymentType === PAYMENT_TYPES.STRIPE_CARD &&
      isEmpty(cards.find(card => card?.provider === PAYMENT_TYPES.STRIPE_CARD))
    ) {
      openStripeCardModal();
    }
  }, [modalDemandingPaymentType]);

  useEffect(() => {
    if (selectedPaymentType) {
      setModalDemandingPaymentType(selectedPaymentType);
    }
  }, [selectedPaymentType]);

  const handleClickConfirmStripeCard = () => {
    setIsStripeCardModalOpen(false);

    onClickPayment({ paymentType: PAYMENT_TYPES.STRIPE_CARD });
  };

  const handleClickConfirmPayUCard = () => {
    setIsPayUCardModalOpen(false);

    onClickPayment({ paymentType: PAYMENT_TYPES.PAYU_CARD });
  };

  const handleClickConfirmPaymentPayPo = (metadata, { setSubmitting }) => {
    setIsPayPoModalOpen(false);

    setSubmitting(false);

    onClickPayment({ paymentType: modalDemandingPaymentType, metadata });
  };

  if (isEmpty(payments)) {
    return null;
  }

  const hasPaymentType = paymentType =>
    payments.some(type => paymentType.includes(type));
  const hasPayPoPayment = hasPaymentType([
    PAYMENT_TYPES.PAYPO,
    PAYMENT_TYPES.PAYU_PAYPO,
  ]);
  const hasPayUCardPayment = hasPaymentType([PAYMENT_TYPES.PAYU_CARD]);
  const hasStripeCardPayment = hasPaymentType([PAYMENT_TYPES.STRIPE_CARD]);

  const formatOptionLabel = ({ value, isSelected }) => {
    const typeComponents = paymentTypeProps[value];

    const cardPaymentTypes = [
      PAYMENT_TYPES.STRIPE_CARD,
      PAYMENT_TYPES.PAYU_CARD,
    ];

    if (cardPaymentTypes.includes(value)) {
      const typeComponents = paymentTypeProps[value];

      if ('component' in typeComponents) {
        return typeComponents.component(
          selectedPaymentType === value,
          isSelectMode
        );
      }
    }

    return (
      <div
        tw="relative flex flex-col flex-wrap"
        css={isSelected && typeComponents?.content && tw`py-3`}
      >
        <div tw="flex items-center">
          <div tw="w-14 mr-2 flex justify-center">{typeComponents?.image}</div>
          <div>{typeComponents?.text}</div>
        </div>
        {isSelected && (
          <div tw="ml-1" style={{ width: '95%' }}>
            {typeComponents?.content}
          </div>
        )}
      </div>
    );
  };

  if (
    (isEmpty(sortedPaymentTypes) && !isEmpty(payments)) ||
    basket?.price?.afterDiscount <= 0
  ) {
    return null;
  }

  const isPaymentMetadataMissing =
    additionalMetadataPaymentTypes.includes(selectedPaymentType) &&
    !modalDemandingPaymentType;

  return (
    <>
      <div tw="mb-4">
        {isSelectMode ? (
          <PaymentTypesReorderPage
            sortedPaymentTypes={sortedPaymentTypes}
            selectedPaymentType={selectedPaymentType}
            handleClickPayment={handleClickPayment}
            formatOptionLabel={formatOptionLabel}
          />
        ) : (
          <div data-cy="payment-types">
            {!isEmpty(sortedPaymentTypes) ? (
              sortedPaymentTypes.map((paymentType, index) => {
                const typeComponents = paymentTypeProps[paymentType];
                const handleClickPaymentItem = () => {
                  handleClickPayment(paymentType);
                };

                if ('component' in typeComponents) {
                  return typeComponents.component(
                    selectedPaymentType === paymentType
                  );
                }

                return (
                  <PaymentTypeItem
                    key={index}
                    onClick={handleClickPaymentItem}
                    isSelected={
                      selectedPaymentType === paymentType &&
                      ([
                        PAYMENT_TYPES.PAYU_CARD,
                        PAYMENT_TYPES.STRIPE_CARD,
                      ].includes(selectedPaymentType)
                        ? isSelectedProviderCardAvailable
                        : true)
                    }
                    type={paymentType}
                    {...typeComponents}
                  />
                );
              })
            ) : (
              <span>
                {t(
                  '$*components.paymentTypes.noPaymentTypes',
                  'Brak metod płatności, skontaktuj się z BOK.'
                )}
              </span>
            )}
          </div>
        )}
        {hasStripeCardPayment && (
          <CardModal
            paymentType={PAYMENT_TYPES.STRIPE_CARD}
            isOpen={isStripeCardModalOpen}
            closeModal={closeStripeCardModal}
            onClickConfirm={handleClickConfirmStripeCard}
          />
        )}
        {hasPayUCardPayment && (
          <CardModal
            paymentType={PAYMENT_TYPES.PAYU_CARD}
            isOpen={isPayUCardModalOpen}
            closeModal={closePayUCardModal}
            onClickConfirm={handleClickConfirmPayUCard}
          />
        )}
        {hasPayPoPayment && (
          <PayPoModal
            isOpen={isPayPoModalOpen}
            closeModal={closePayPoModal}
            onClickConfirm={handleClickConfirmPaymentPayPo}
          />
        )}
      </div>
      <p
        tw="text-sm text-red-1 py-0 my-0 mb-1"
        aria-hidden={!isPaymentMetadataMissing}
        css={isPaymentMetadataMissing ? tw`visible` : tw`invisible`}
      >
        {t(
          '$*reorderPage.payment.missingDataWarning',
          'Wybierz ponownie metodę płatności i uzupełnij dane'
        )}
      </p>
    </>
  );
};

const PaymentTypeItem = ({
  image,
  text,
  rightCol = null,
  content = null,
  ...restProps
}) => {
  return (
    <PaymentType {...restProps}>
      <PaymentType.Image>{image}</PaymentType.Image>
      <PaymentType.Text>{text}</PaymentType.Text>
      <PaymentType.RightCol>{rightCol}</PaymentType.RightCol>
      <PaymentType.Content>{content}</PaymentType.Content>
    </PaymentType>
  );
};

PaymentTypeItem.displayName = 'PaymentTypeItem';
PaymentTypes.displayName = 'PaymentTypes';

export default PaymentTypes;
