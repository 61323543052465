import { useQuery } from '@tanstack/react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/axiosInstance';

const getPayUPaymentMethods = async () => {
  const response = await axios.get(ENDPOINT.PAYU_PAYMENT_METHODS);

  return response?.data || [];
};

const usePayUPaymentMethods = (options = {}) =>
  useQuery({
    queryKey: ['payUPaymentMethods'],
    queryFn: getPayUPaymentMethods,
    ...options,
  });

export default usePayUPaymentMethods;
