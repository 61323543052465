import { Trans, useTranslation } from 'next-i18next';

import { useState } from 'react';
import tw from 'twin.macro';

import InfoIcon from '@components/elements/InfoIcon/InfoIcon';
import Modal from '@components/elements/Modal/Modal';

const PayPoInfo = ({ reorderPage = false }) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <button
        type="button"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        {reorderPage ? (
          <p tw="mt-2">
            <Trans
              i18nKey="$*components.paymentTypes.paypo.paymentInformation"
              components={{
                span: <span tw="mt-2 text-primary cursor-pointer" />,
              }}
            />
          </p>
        ) : (
          <InfoIcon />
        )}
      </button>
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        styles={{
          container: tw`z-60`,
        }}
      >
        <Modal.Header>
          {t('$*components.paymentTypes.paypo.howDoesItWork')}
        </Modal.Header>
        <Modal.Content>
          <Trans
            tw="text-justify"
            parent="p"
            i18nKey="$*components.paymentTypes.paypo.tooltip"
          />
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default PayPoInfo;
