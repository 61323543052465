import { type ReactNode } from 'react';

type Props = { href: string; children?: ReactNode };

const ExternalLink = ({ href, children }: Props) => {
  return (
    <a tw="font-semibold" href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

export default ExternalLink;
