import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

import isEmpty from 'lodash/isEmpty';

import BankIcon from '@assets/icons/BankIcon';
import CashIcon from '@assets/icons/CashIcon';
import StripeCardIcon from '@assets/icons/StripeCardIcon';
import TransferIcon from '@assets/icons/TransferIcon';
import PAYMENT_TYPES from '@constants/paymentTypes';
import usePaymentCards from '@hooks/usePaymentCards';
import checkIfPaymentTypesIncludesCard from '@utils/checkIfPaymentTypesIncludesCard';

import BlikInfo from './BlikInfo/BlikInfo';
import PayPoInfo from './PayPoInfo/PayPoInfo';
import PaymentRightCol from './PaymentRightCol/PaymentRightCol';

const BlikInputCode = dynamic(() => import('./BlikInputCode/BlikInputCode'));
const BlikInputCodeContent = dynamic(() =>
  import('./BlikInputCode/BlikInputCodeContent')
);
const PayUPayByLinks = dynamic(() => import('./PayUPayByLinks/PayUPayByLinks'));
const PayByLinksContent = dynamic(() =>
  import('./PayUPayByLinks/PayByLinksContent')
);
const SavedPaymentCard = dynamic(() => import('./SavedPaymentCard'));

const usePaymentTypeProps = ({
  blikCode,
  payMethods,
  setBlikCode,
  selectedPaymentType,
  onClickPayment,
  currentPayMethodName,
  isBlikExternalMode,
}) => {
  const { t } = useTranslation();
  const { data: cards = [], refetch: refetchCards } = usePaymentCards({
    enabled: checkIfPaymentTypesIncludesCard(payMethods || []),
  });

  const payUCard = cards.find(({ provider }) => {
    return provider === PAYMENT_TYPES.PAYU_CARD;
  });
  const stripeCard = cards.find(({ provider }) => {
    return provider === PAYMENT_TYPES.STRIPE_CARD;
  });

  const blikProps = paymentType => {
    return {
      image: <img src="/images/payment/blik.png" alt="" />,
      text: t('$*components.paymentTypes.blik.label', 'BLIK'),
      ...(!isBlikExternalMode
        ? {
            component: isSelected => (
              <BlikInputCode
                onClickPayment={() => onClickPayment({ paymentType })}
                blikCode={blikCode}
                setBlikCode={setBlikCode}
                isSelected={isSelected}
              />
            ),
          }
        : {}),
      content: (
        <BlikInputCodeContent
          blikCode={blikCode}
          setBlikCode={setBlikCode}
          isSelected={paymentType === selectedPaymentType}
        />
      ),
      rightCol: (
        <PaymentRightCol>
          <BlikInfo />
        </PaymentRightCol>
      ),
    };
  };

  const payPoProps = {
    image: (
      <img
        tw="min-w-14"
        src="/images/payment/paypo.png"
        alt=""
        loading="lazy"
      />
    ),
    text: t(
      '$*components.paymentTypes.paypo.label',
      'PayPo - Kup teraz, zapłać za 30 dni'
    ),
    rightCol: (
      <PaymentRightCol>
        <PayPoInfo />
      </PaymentRightCol>
    ),
  };

  return {
    [PAYMENT_TYPES.BLUE_MEDIA_BLIK]: blikProps(PAYMENT_TYPES.BLUE_MEDIA_BLIK),
    [PAYMENT_TYPES.PAYU_BLIK]: blikProps(PAYMENT_TYPES.PAYU_BLIK),
    [PAYMENT_TYPES.PAYU]: {
      image: <img src="/images/payment/payu.png" alt="" loading="lazy" />,
      text: t('$*components.paymentTypes.payu.label', 'PayU'),
    },
    [PAYMENT_TYPES.PAYU_CARD]: {
      image: <img src="/images/payment/visa-mc.svg" alt="" loading="lazy" />,
      text: t('$*components.paymentTypes.payuCard.label', 'Karta'),
      ...(!isEmpty(payUCard)
        ? {
            component: (isSelected, isSelectMode) => {
              return (
                <SavedPaymentCard
                  cardData={payUCard}
                  noBorder={isSelectMode}
                  isSelected={isSelected}
                  allowRemove={!isSelectMode}
                  refetchCards={refetchCards}
                  onClickPayment={() =>
                    onClickPayment({ paymentType: PAYMENT_TYPES.PAYU_CARD })
                  }
                />
              );
            },
          }
        : {}),
    },
    [PAYMENT_TYPES.BLUE_MEDIA]: {
      image: <img src="/images/payment/blue-media.png" alt="" loading="lazy" />,
      text: t('$*components.paymentTypes.blueMedia.label', 'Blue Media'),
    },
    [PAYMENT_TYPES.TPAY]: {
      image: <img src="/images/payment/tpay.png" alt="" loading="lazy" />,
      text: t('$*components.paymentTypes.tpay.label', 'Tpay'),
    },
    [PAYMENT_TYPES.STRIPE_LINK]: {
      image: <img src="/images/payment/stripe.png" alt="" loading="lazy" />,
      text: t('$*components.paymentTypes.stripeOnline.label', 'Stripe'),
    },
    [PAYMENT_TYPES.PAYNOW]: {
      image: <img src="/images/payment/paynow.png" alt="" loading="lazy" />,
      text: t('$*components.paymentTypes.paynow.label', 'PayNow'),
    },
    [PAYMENT_TYPES.PAYPO]: payPoProps,
    [PAYMENT_TYPES.PAYU_PAYPO]: payPoProps,
    [PAYMENT_TYPES.BLUE_MEDIA_CARD]: {
      image: <img src="/images/payment/visa-mc.svg" alt="" loading="lazy" />,
      text: t('$*components.paymentTypes.blueMediaCard.label', 'Karta'),
    },
    [PAYMENT_TYPES.STRIPE_CARD]: {
      image: <StripeCardIcon tw="w-10" />,
      text: t('$*components.paymentTypes.stripeCard.label', 'Płatność kartą'),
      ...(!isEmpty(stripeCard)
        ? {
            component: (isSelected, isSelectMode) => {
              return (
                <SavedPaymentCard
                  cardData={stripeCard}
                  noBorder={isSelectMode}
                  isSelected={isSelected}
                  allowRemove={!isSelectMode}
                  refetchCards={refetchCards}
                  onClickPayment={() =>
                    onClickPayment({ paymentType: PAYMENT_TYPES.STRIPE_CARD })
                  }
                />
              );
            },
          }
        : {}),
    },
    [PAYMENT_TYPES.BANK_WIRE]: {
      image: <TransferIcon tw="w-10" />,
      text: t('$*components.paymentTypes.bankWire.label', 'Przelew tradycyjny'),
    },
    [PAYMENT_TYPES.CASH]: {
      image: <CashIcon tw="w-10" />,
      text: t(
        '$*components.paymentTypes.cash.label',
        'Płatność w punkcie odbioru'
      ),
    },
    [PAYMENT_TYPES.PAYU_PAY_BY_LINKS]: {
      image: <BankIcon tw="w-10 h-8" />,
      text:
        currentPayMethodName || t('$*components.paymentTypes.PBL', 'Przelew'),
      component: isSelected => {
        return (
          <PayUPayByLinks
            isSelected={isSelected}
            onClickPayment={onClickPayment}
          />
        );
      },
      content: !currentPayMethodName && (
        <PayByLinksContent onClickPayment={onClickPayment} narrow />
      ),
    },
  };
};

export default usePaymentTypeProps;
