import { Children, cloneElement } from 'react';
import { css } from '@emotion/react';
import kebabCase from 'lodash/kebabCase';
import tw from 'twin.macro';

import SpinnerThirdIcon from '@assets/icons/SpinnerThirdIcon';
import {
  getChildByDisplayName,
  isChildTypeDisplayNameEqualTo,
} from '@utils/helpers';
import { cssMerge } from '@utils/styleHelpers';

const PaymentType = ({
  children,
  disabled = false,
  isLoading = false,
  isSelected,
  onClick,
  size = 'lg',
  styles = {},
  type,
}) => {
  const childrenWithProps = Children.map(children, child =>
    cloneElement(child, { size })
  );

  const contentChildren = getChildByDisplayName(childrenWithProps, 'Content');
  const coreChildrenWithProps = childrenWithProps.filter(
    child => !isChildTypeDisplayNameEqualTo(child, 'Content')
  );

  const styleBySize = {
    md: tw`my-2 text-sm`,
    lg: tw`my-4`,
    xl: tw`my-4`,
  }[size];

  const twStyle = cssMerge({
    defaultCss: [
      tw`border-2 rounded-lg py-3 px-3.5 cursor-pointer`,
      isSelected ? tw`border-primary text-primary` : tw`border-gray-1`,
      isLoading ? tw`opacity-60` : tw`opacity-100`,
      styleBySize,
    ],
    ...styles,
  });

  return (
    <div tw="relative">
      {isLoading && (
        <div tw="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <SpinnerThirdIcon tw="w-5 text-primary animate-spin" />
        </div>
      )}
      <div
        onClick={e => {
          !disabled && onClick?.(e);
        }}
        css={twStyle}
        data-cy={`payment-type payment-type--${kebabCase(type)}`}
      >
        <div style={{ minHeight: '30px' }} tw="flex items-center w-full">
          {coreChildrenWithProps}
        </div>
        {contentChildren}
      </div>
    </div>
  );
};

const Image = ({ children, size }) => {
  return (
    <div
      css={css`
        ${tw`mr-3`};
        ${size === 'md' && tw`w-10`}
        ${size === 'lg' && tw`w-14`}
        flex: 0 0 auto;

        img,
        svg {
          ${size === 'md' && 'max-height: 24px;'}
          ${size === 'lg' && 'max-height: 30px;'}
          ${size === 'xl' && 'max-height: 45px;'}
          ${tw`table mx-auto`}
        }
      `}
    >
      {children}
    </div>
  );
};

const Text = ({ children }) => <div tw="text-sm sm:text-base">{children}</div>;
const RightCol = ({ children }) => children;
const Content = ({ children }) => children;

Image.displayName = 'Image';
Text.displayName = 'Text';
RightCol.displayName = 'RightCol';
Content.displayName = 'Content';

PaymentType.Image = Image;
PaymentType.Text = Text;
PaymentType.RightCol = RightCol;
PaymentType.Content = Content;

PaymentType.displayName = 'PaymentType';

export default PaymentType;
