import { Trans, useTranslation } from 'next-i18next';

import { useState } from 'react';
import tw from 'twin.macro';

import InfoIcon from '@components/elements/InfoIcon/InfoIcon';
import Modal from '@components/elements/Modal/Modal';

import ExternalLink from './ExternalLink/ExternalLink';

const BlikInfo = ({ reorderPage = false }) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <button
        type="button"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        {reorderPage ? (
          <p tw="mt-2">
            <Trans
              i18nKey="$*components.paymentTypes.blik.paymentInformation"
              components={{
                span: <span tw="mt-2 text-primary cursor-pointer" />,
              }}
            />
          </p>
        ) : (
          <InfoIcon />
        )}
      </button>
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        styles={{
          container: tw`z-60`,
        }}
      >
        <Modal.Header>
          {t('$*newOrderCreatePage.orderForm.summary.PayUBlikTitle')}
        </Modal.Header>
        <Modal.Content>
          <Trans
            tw="text-justify"
            parent="p"
            i18nKey="$*newOrderCreatePage.orderForm.summary.PayUBlikDescription"
            components={[
              // eslint-disable-next-line react/jsx-key
              <ExternalLink href="https://static.payu.com/sites/terms/files/payu_terms_of_service_single_transaction_pl_pl.pdf" />,
              // eslint-disable-next-line react/jsx-key
              <ExternalLink href="https://static.payu.com/sites/terms/files/payu_privacy_policy_pl_pl.pdf" />,
            ]}
          />
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default BlikInfo;
