const TransferIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 4385 2949"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path d="M313.79 2284.5L952.02 2820c30.83 25.9 34.86 71.9 8.99 102.8-25.87 30.8-71.9 34.8-102.73 9L0 2211.6l858.18-720.1c30.83-25.9 76.86-21.9 102.73 8.9 25.87 30.9 21.84 76.9-8.99 102.8l-652.69 535.5h2591.56c40.24 0 72.92 32.6 72.92 72.9 0 40.2-32.68 72.9-72.92 72.9h-2577zM4070.64 664.3l-638.23-535.5c-30.83-25.9-34.85-71.9-8.99-102.7 25.87-30.9 71.9-34.9 102.73-9l858.29 720.2-858.19 720.1c-30.83 25.8-76.86 21.8-102.73-9-25.87-30.8-21.84-76.9 8.99-102.7l652.69-535.5H1493.64c-40.24 0-72.91-32.7-72.91-73 0-40.2 32.67-72.9 72.91-72.9h2577z" />
  </svg>
);

export default TransferIcon;
