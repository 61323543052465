import type { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const PaymentRightCol = ({ children }: Props) => {
  return <div tw="ml-auto pl-3">{children}</div>;
};

export default PaymentRightCol;
